import React from "react";

const sliderItems = [
  {
    webDescription: "",
    featureName: "Interchange Trading",
    webPageTitle:
      "Trade across multiple blockchains with zero centralization of any kind.",
    btnTitle: "Interchange Trading",
    imageUrl: "https://i.imgur.com/JzqczDM.png"
  },
  {
    webDescription: "",
    featureName: "Interchange Bots",
    webPageTitle:
      "Provide liquidity and generate profit using the Overline Interchange's built-in trading bots",
    btnTitle: "Interchange Bots",
    imageUrl: "https://i.imgur.com/mvf6KaN.png"
  },
  {
    webDescription: "",
    featureName: "Multichain Portfolio",
    webPageTitle:
      "Track your cross-chain digital asset portfolio all from a single interface.",
    btnTitle: "Multichain Portfolio",
    imageUrl: "https://i.imgur.com/5KpXtIW.png"
  },
  {
    webDescription: "",
    featureName: "Emblem Leasing",
    webPageTitle:
      "Lease your EMB to miners using the Overline Interchange's in-built smart contract.",
    btnTitle: "Emblem Leasing",
    imageUrl: "https://i.imgur.com/6qlCtDy.png"
  }
];

const Features = () => <Slider items={sliderItems} />;

class Slider extends React.Component {
  constructor() {
    super();
    this.state = {
      currentIndex: 0,
      items: []
    };
  }

  handleSelect = e => {
    this.setState({ currentIndex: Number(e.target.dataset.id) });
  };

  componentDidMount() {
    const { items } = this.props;
    if (items) {
      this.setState({ items });
    }
    setInterval(() => {
      const { currentIndex, items } = this.state;
      const itemsLength = items.length;
      const idx = (currentIndex + 1) % itemsLength;
      this.setState({ currentIndex: idx });
    }, 5000);
  }

  render() {
    const { currentIndex, items } = this.state;
    const sliderContent = items.map((item, index) => {
      const left = `${index * 100}%`;
      const backgroundImage = `url(${item.imageUrl})`;
      const sty = {
        left,
        backgroundImage,
        display: currentIndex != index ? "none" : ""
      };
      const cls = `feature_slide ${
        index == currentIndex ? "featureActive" : ""
      }`;
      return <div key={index} className={cls} style={sty}></div>;
    });
    const selectItems = items.map((item, index) => {
      const cls = `featureSelect__item ${
        index == currentIndex ? "featureActive" : ""
      }`;
      return (
        <span
          key={index}
          data-id={index}
          className={cls}
          onClick={this.handleSelect}
        >
          {item.btnTitle}
        </span>
      );
    });

    const selectedItemObj = items[currentIndex];
    let webPageTitle = "Interchange";
    let webDescription = "";
    let featureName = "";
    if (selectedItemObj) {
      webPageTitle = selectedItemObj.webPageTitle;
      webDescription = selectedItemObj.webDescription;
      featureName = selectedItemObj.featureName;
    }
    return (
      <section className="keyFeatures">
        <div className="featuresWrapper">
          <h2 className="about-tagline bigtitle secondtitle">
            All within a comprehensive trading environment.
          </h2>
          <div className="featureSectionContainer">
            <div className="featureSlider">
              <div className="featureSlider__panel__controls">
                <div className="feature-name">{featureName}</div>
                <div className="featureTitle">{webPageTitle}</div>
                <div className="featureSelect">{selectItems}</div>
              </div>
              <div className="featureSlider_panel featureSlider_panel_frame">
                {sliderContent}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Features;
