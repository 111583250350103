import React from "react";
import MobileMenu from "./components/MobileMenu";
import Hero from "./components/Hero";
import About from "./components/About";
import Features from "./components/Features";
import GettingStarted from "./components/GettingStarted";
import MultichainComparison2 from "./components/MultichainComparison2";
import PairSection from "./components/PairSection";
import DeveloperAPI from "./components/DeveloperAPI";
import PlatformComparison from "./components/PlatformComparison";
import Comparison from "./components/Comparison";
import Footer from "./components/Footer";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/style.css";
import "./styles/style.scss";
import "./styles/buttons.scss";
import "./styles/mobilemenu-styles.scss";
import "./styles/hero-styles.css";
import "./styles/about-styles.css";
import "./styles/features-styles.scss";
import "./styles/exchange-comp-styles.css";
import "./styles/setup-styles.scss";
import "./styles/multichain-comp-styles.css";
import "./styles/developer-styles.css";
import "./styles/footer-styles.css";
import "./styles/pair-styles.scss";

function App() {
  return (
    <div id="main">
      {/*    "Since the natural tendencies of mankind are so bad that it is not safe to allow them liberty, how comes it to pass that the tendencies of organizers are always good? Do not the legislators and their agents form a part of the human race? Do they consider that they are composed of different materials from the rest of mankind? They say society, when left to itself, rushes to inevitable destruction, because its instincts are perverse. They pretend to stop it in its downward course, and to give it a better direction. They have, therefore, received from heaven, intelligence and virtues which place them beyond and above mankind: let them show their title to this superiority. They would be our shepherds. and we are to be their flock." - F. Bastiat, The Law

      “Knowledge by itself is not power, but it holds the potential for power if we use it a s a guide for action. Truth will always be defeated by tyranny unless the people are willing to step forward and put their lives into the battle. The future belongs, not to ideas, but to people who act on those ideas.” ― G. Edward Griffin, The Creature from Jekyll Island: A Second Look at the Federal Reserve */}
      <MobileMenu />
      <Hero />
      <About />
      <PairSection />
      <PlatformComparison />
      <Features />
      {/*<GettingStarted />*/}
      <MultichainComparison2 />
      <Comparison />
      <DeveloperAPI />
      <Footer />
    </div>
  );
}

export default App;
