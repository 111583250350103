import React, { Component } from "react";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { getGasPrice, initPrices } from "./prices";
import Decimal from "decimal.js";
import ReactTooltip from "react-tooltip";
import { assets } from "./config";
import renderInput from "./renderInput";
import "./comparison-styles.css";
// import "./main.css";

import $ from "jquery";

class Comparison extends Component {
  constructor() {
    super();
    this.state = {
      gasPrice: new Decimal(0),
      fromAsset: "ETH",
      toAsset: "EMB",
      fromAmount: 0,
      toAmount: 0,
    };
  }
  async componentDidMount() {
    let _this = this;
    let gasPrice = await getGasPrice();
    if (gasPrice) {
      _this.setState({
        gasPrice: new Decimal(gasPrice).div(new Decimal(Math.pow(10, 18))),
      });
    }

    let prices = await initPrices();
    if (prices && prices.eth && prices.emb) {
      _this.setState({ prices });
      let price = new Decimal(prices.eth).div(new Decimal(prices.emb));
      this.setState({
        fromAmount: 40,
        toAmount: new Decimal(40).mul(price).toFixed(5),
      });
      $("#fromField")[0].value = 40;
      $("#toField")[0].value = new Decimal(40).mul(price).toFixed(5);
    }

    setInterval(async () => {
      let gasPrice = await getGasPrice();
      if (gasPrice) {
        _this.setState({
          gasPrice: new Decimal(gasPrice).div(new Decimal(Math.pow(10, 18))),
        });
      }
      let prices = await initPrices();
      if (prices && prices.eth && prices.emb) {
        if (!this.state.prices) {
          let price = new Decimal(prices.eth).div(new Decimal(prices.emb));
          this.setState({
            fromAmount: 40,
            toAmount: new Decimal(40).mul(price).toFixed(5),
          });
          $("#fromField")[0].value = 40;
          $("#toField")[0].value = new Decimal(40).mul(price).toFixed(5);
        }
        _this.setState({ prices });
      }
    }, 10000);
  }
  renderServiceLogo(name) {
    return (
      <img
        // style={{ filter }}
        src={`../../../${name.toLowerCase()}.png`}
        className="chain-logo"
      />
    );
  }
  renderSection({ name, fees, totalCheaper }) {
    let className = "row center greenValue";
    if (name === "Uniswap") className = "row center redValue";
    return (
      <div
        className="col s12 m4"
        style={{
          marginTop: "100px",
          paddingLeft: "3rem",
          paddingRight: "3rem",
        }}
      >
        {this.renderServiceLogo(name)}
        <h4 style={{ color: "black", textAlign: "center" }}> {name} </h4>
        {!fees && <h5 style={{ color: "black" }}>Not available on Uniswap.</h5>}
        <div className="container">
          {fees &&
            fees.map(({ title, val, chain, usd }) => {
              return (
                <div
                  className={title === "Total Fee" ? className : "row center"}
                  style={{
                    textAlign: "left",
                    color: "black",
                    marginTop: "30px",
                    paddingTop: "5px",
                    paddingBlock: "5px",
                  }}
                >
                  <div className="col s6">{title}</div>
                  <div className="col s4" style={{ textAlign: "right" }}>
                    {val} {chain}
                  </div>
                  <div className="col s2" style={{ textAlign: "right" }}>
                    ${usd}
                  </div>
                </div>
              );
            })}
          {totalCheaper && (
            <div className="col s12 center">
              <h4
                className="percentage"
                style={{
                  color: "black",
                  marginTop: "1.5rem",
                  fontWeight: "600",
                }}
              >
                {totalCheaper}% Cheaper
              </h4>
            </div>
          )}
        </div>
      </div>
    );
  }
  renderFees() {
    let ethUSD =
      this.state.prices && this.state.prices.eth
        ? new Decimal(this.state.prices.eth)
        : new Decimal(0);
    let olUSD =
      this.state.prices && this.state.prices.ol
        ? new Decimal(this.state.prices.ol)
        : new Decimal(0);
    console.log("olUSD", olUSD);

    let baseUniFee = new Decimal(130000).mul(this.state.gasPrice);
    let baseUniFeeUSD = baseUniFee.mul(ethUSD);

    let liquidityFee = this.state.fromAmount
      ? new Decimal(this.state.fromAmount).mul(new Decimal(0.003))
      : new Decimal(0);

    let liquidityUSD =
      this.state.prices && this.state.prices[this.state.fromAsset.toLowerCase()]
        ? liquidityFee.mul(
            new Decimal(this.state.prices[this.state.fromAsset.toLowerCase()])
          )
        : new Decimal(0);

    let erc20Fee = new Decimal(40000).mul(this.state.gasPrice);
    let ercUSD = erc20Fee.mul(ethUSD);

    let embFee = new Decimal(29000).mul(this.state.gasPrice);
    let embUSD = embFee.mul(ethUSD);

    let minerUSD = new Decimal(0.0043326).mul(olUSD);

    let totalUni = baseUniFeeUSD.add(liquidityUSD);
    let totalInterchange = minerUSD.add(ercUSD);
    let cheaper = new Decimal(1).sub(totalInterchange.div(totalUni));

    let uniFees = [
      {
        title: "Uniswap Gas Fee",
        val: baseUniFee.toFixed(5),
        chain: "ETH",
        usd: baseUniFeeUSD.toFixed(3),
      },
      {
        title: "Uniswap Liquidity Fee",
        val: liquidityFee.toFixed(5),
        chain: this.state.fromAsset.toUpperCase(),
        usd: liquidityUSD.toFixed(3),
      },
      {
        title: "Total Fee",
        usd: totalUni.toFixed(3),
      },
    ];
    if (
      ["btc", "neo", "wav", "lsk"].includes(
        this.state.fromAsset.toLowerCase()
      ) ||
      ["btc", "neo", "wav", "lsk"].includes(this.state.toAsset.toLowerCase())
    ) {
      uniFees = null;
    }
    let comparison = [
      { name: "Uniswap", fees: uniFees },
      {
        name: "Interchange",
        fees: [
          {
            title: "ERC-20 Gas Fee",
            val: erc20Fee.toFixed(5),
            chain: "ETH",
            usd: ercUSD.toFixed(3),
          },
          {
            title: "Overline Miner Fee",
            val: 0.0043326,
            chain: "OL",
            usd: minerUSD.toFixed(4),
          },
          {
            title: "Total Fee",
            usd: totalInterchange.toFixed(3),
          },
        ],
        totalCheaper: uniFees ? cheaper.mul(new Decimal(100)).toFixed(2) : null,
      },
    ];

    return (
      <div className="comparison-text-container">
        <div
          className="inner-container center row"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <div className="col s12 desktop">
            {this.renderSection(comparison[0])}
            {this.state.prices && this.state.prices.eth && this.renderCompare()}
            {this.renderSection(comparison[1])}
          </div>
          <div className="col s12 mobile">
            {this.state.prices && this.state.prices.eth && this.renderCompare()}
            {this.renderSection(comparison[0])}
            {this.renderSection(comparison[1])}
          </div>
        </div>
      </div>
    );
  }
  setAsset(title, name) {
    let obj = {};
    obj[name] = title;
    this.setState(obj);

    let from = name === "fromAsset" ? title : this.state.fromAsset;
    let to = name === "toAsset" ? title : this.state.toAsset;

    if (
      this.state.prices &&
      this.state.prices[from.toLowerCase()] &&
      this.state.prices[to.toLowerCase()]
    ) {
      let price = new Decimal(this.state.prices[from.toLowerCase()]).div(
        new Decimal(this.state.prices[to.toLowerCase()])
      );
      this.setState({
        toAmount: new Decimal(this.state.fromAmount).mul(price).toFixed(5),
      });
      $("#toField")[0].value = new Decimal(this.state.fromAmount)
        .mul(price)
        .toFixed(5);
    }
    // }
    // else {
    //   let price = new Decimal(1).div(new Decimal(this.state.prices[this.state.fromAsset.toLowerCase()]).div(new Decimal(this.state.prices[this.state.toAsset.toLowerCase()])))
    //   this.setState({fromAmount:new Decimal(this.state.toAmount).mul(price).toFixed(5)})
    //   $('#fromField')[0].value = new Decimal(this.state.toAmount).mul(price).toFixed(5);
    // }
  }

  renderImage(title) {
    return (
      <img
        // style={{ filter }}
        src={`../../../${title.toLowerCase()}.png`}
        className="asset-logo"
      />
    );
  }

  renderChooseAsset(asset, name) {
    let other =
      name === "fromAsset" ? this.state.toAsset : this.state.fromAsset;
    let titles = assets
      .filter((a) => a != "L-EMB" && a != other)
      .map((title, i) => {
        let border =
          this.state[name].toLowerCase() == title.toLowerCase()
            ? "1px solid white"
            : "1px solid grey";
        return (
          <div
            key={i}
            className="chooseFilter assetChoice"
            style={{ width: 200 }}
          >
            <div style={{ marginTop: "10px" }}>
              <div className="row">
                <div className="col">{this.renderImage(title)}</div>
                <div className="col">
                  <p
                    onClick={this.setAsset.bind(this, title, name)}
                    style={{
                      padding: "5px 10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    {title.replace("NRG", "OL")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      });

    let _this = this;
    return (
      <div className={`center from-selection`} style={{}}>
        <div
          className="from-selection"
          id={"div" + name}
          style={{
            bottom: 0,
          }}
          ref={(c) => {
            _this[`ref` + name] = c;
          }}
          data-tip={`hello` + name}
          data-for={"bid_ask_tooltip" + name}
          //   onClick={() => {
          //     ReactTooltip.show(_this[`ref` + name]);
          //   }}
          clickable={true}
          //   onClick={() => {
          //     ReactTooltip.show(_this[`ref` + name]);
          //   }}
          onMouseOver={() => {
            ReactTooltip.show(_this[`ref` + name]);
          }}
          onMouseLeave={() => {
            ReactTooltip.hide(_this[`ref` + name]);
          }}
        >
          <div className="row asset-selection">
            <div className="col s3" style={{ marginBottom: "0.5rem" }}>
              {this.renderImage(asset)}
            </div>
            <div className="col s6">
              <p
                style={{ marginTop: "0.25rem" }}
                className="marketType chosen-asset"
              >
                {asset.replace("NRG", "OL")}
              </p>
            </div>
            <div className="col s3">
              <svg
                width="12"
                height="7"
                viewBox="0 0 12 7"
                fill="none"
                class="sc-drKuOJ AJCeH"
              >
                <path d="M0.97168 1L6.20532 6L11.439 1" stroke="#AEAEAE"></path>
              </svg>
            </div>
          </div>
        </div>
        <ReactTooltip
          data-id={"bid_ask_tooltip" + name}
          id={"bid_ask_tooltip" + name}
          place="bottom"
          type="light"
          effect="solid"
          className="extraClass choose-dropdown"
          delayHide={50}
          clickable={true}
          onClick={() => {
            ReactTooltip.hide(_this[`ref` + name]);
          }}
          style={{ backgroundColor: "#fff", borderRadius: 30 }}
        >
          <div className="col s12">{titles}</div>
        </ReactTooltip>
      </div>
    );
  }
  onChangeFrom(e) {
    this.setState({ fromAmount: e.target.value });
    if (
      this.state.prices &&
      this.state.prices[this.state.fromAsset.toLowerCase()] &&
      this.state.prices[this.state.toAsset.toLowerCase()]
    ) {
      let price = new Decimal(
        this.state.prices[this.state.fromAsset.toLowerCase()]
      ).div(new Decimal(this.state.prices[this.state.toAsset.toLowerCase()]));
      if (parseInt(e.target.value) >= 0) {
        this.setState({
          toAmount: new Decimal(e.target.value).mul(price).toFixed(5),
        });
        $("#toField")[0].value = new Decimal(e.target.value)
          .mul(price)
          .toFixed(5);
      } else {
        this.setState({
          toAmount: 0,
        });
        $("#toField")[0].value = 0;
      }
    }
  }
  onChangeTo(e) {
    this.setState({ toAmount: e.target.value });
    if (
      this.state.prices &&
      this.state.prices[this.state.fromAsset.toLowerCase()] &&
      this.state.prices[this.state.toAsset.toLowerCase()]
    ) {
      let price = new Decimal(1).div(
        new Decimal(this.state.prices[this.state.fromAsset.toLowerCase()]).div(
          new Decimal(this.state.prices[this.state.toAsset.toLowerCase()])
        )
      );
      if (parseInt(e.target.value) >= 0) {
        this.setState({
          fromAmount: new Decimal(e.target.value).mul(price).toFixed(5),
        });
        $("#fromField")[0].value = new Decimal(e.target.value)
          .mul(price)
          .toFixed(5);
      } else {
        this.setState({
          fromAmount: 0,
        });
        $("#fromField")[0].value = 0;
      }
    }
  }
  renderTo() {
    return (
      <div className="col s12">
        <div className="comparison-entry">
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col">
                  <p className="subtitle-text">To:</p>
                </div>
              </div>
              <form
                className={{}}
                style={{
                  zoom: 1.1,
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div className="row" style={{ paddingTop: "5px" }}>
                  <Field
                    name="to"
                    _id={"toField"}
                    onChang={this.onChangeTo.bind(this)}
                    component={renderInput}
                    step={"any"}
                    type="number"
                    // label={`To:`}
                    required={true}
                    placeholder="0.0"
                    style={{
                      fontSize: 24,
                      color: "#000",
                    }}
                    // border="none"
                  />
                </div>
              </form>
            </div>
            <div className="col selection-col">
              {this.renderChooseAsset(this.state.toAsset, "toAsset")}
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderFrom() {
    return (
      <div className="col s12" style={{ marginBottom: "2.5rem" }}>
        <div className="comparison-entry">
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col">
                  <p className="subtitle-text">From:</p>
                </div>
              </div>
              <form
                style={{
                  zoom: 1.1,
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div className="row" style={{ paddingTop: "5px" }}>
                  <Field
                    className="field-input"
                    name="from"
                    _id={"fromField"}
                    onChang={this.onChangeFrom.bind(this)}
                    component={renderInput}
                    step={"any"}
                    type="number"
                    // label={`To:`}
                    required={true}
                    placeholder="0.0"
                    style={{ fontSize: 24, color: "#000" }}
                  />
                </div>
              </form>
            </div>
            <div className="col selection-col">
              {this.renderChooseAsset(this.state.fromAsset, "fromAsset")}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCompare() {

    let price = new Decimal(0);
    if(this.state.prices && this.state.prices[this.state.fromAsset.toLowerCase()] && this.state.prices[this.state.toAsset.toLowerCase()]){
      price = new Decimal(1).div(
        new Decimal(this.state.prices[this.state.fromAsset.toLowerCase()]).div(
          new Decimal(this.state.prices[this.state.toAsset.toLowerCase()])
        )
      );
    }
    let oprice = new Decimal(1).div(price);

    price = price.toFixed(5);
    if (price.split(".")[1] === "00000") price = price.split(".")[0] + ".00";

    oprice = oprice.toFixed(5);
    if (oprice.split(".")[1] === "00000") oprice = oprice.split(".")[0] + ".00";

    return (
      <div className="col s12 m4" style={{ marginTop: "100px" }}>
        <div className="col comparison-box">
          {/* <p className="title-text">Compare Fees:</p> */}
          <div className="entry-box">{this.renderFrom()}</div>
          <div className="entry-box">{this.renderTo()}</div>
          <div
            className="col s12 center rates-box"
            style={{ marginTop: "30px", marginBottom: "30px" }}
          >
            <div className="" style={{ paddingTop: 10, paddingBottom: 10 }}>
              <p className="rate-text">
                {price} {this.state.fromAsset} per {this.state.toAsset}
              </p>
              <p className="rate-text" style={{ marginTop: "15px" }}>
                {oprice} {this.state.toAsset} per {this.state.fromAsset}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <section className="comparison-section">
        <div className="comparison-container">
          <div className="comparison-sec-titles">
            <h2 className="about-tagline bigtitle secondtitle">
              Leave prohibitive fees behind.
            </h2>
            <p className="comparison-blurb">
              Interchange's highly optimized ETH transactions enable trades at a
              fraction of the cost of other decentralized exchange competitors,
              with the added benefit of being the first to offer truly
              cross-chain trading.
            </p>
          </div>
          <div className="row render-fees" style={{}}>
            {this.state.prices && this.state.prices.eth && this.renderFees()}
          </div>
        </div>
      </section>
    );
  }
}
//   renderCompare() {
//     let price = new Decimal(1).div(
//       new Decimal(this.props.price[this.state.fromAsset.toLowerCase()]).div(
//         new Decimal(this.props.price[this.state.toAsset.toLowerCase()])
//       )
//     );
//     let oprice = new Decimal(1).div(price);

//     price = price.toFixed(5);
//     if (price.split(".")[1] === "00000") price = price.split(".")[0] + ".00";

//     oprice = oprice.toFixed(5);
//     if (oprice.split(".")[1] === "00000") oprice = oprice.split(".")[0] + ".00";

//     return (
//       <div className="col s4" style={{ marginTop: "100px" }}>
//         <div
//           className="col comparison-box"
//           style={{ marginLeft: "auto", marginRight: "auto", padding: "1rem" }}
//         >
//           <p className="title-text" style={{ marginBottom: "0.5rem" }}>
//             Compare Fees:
//           </p>
//           <div className="entry-box">{this.renderFrom()}</div>
//           <div className="entry-box">{this.renderTo()}</div>
//           <div
//             className="col s12 center rates-box"
//             style={{ marginTop: "30px", marginBottom: "30px" }}
//           >
//             <div className="" style={{ paddingTop: 10, paddingBottom: 10 }}>
//               <p className="rate-text">
//                 {price} {this.state.fromAsset} per {this.state.toAsset}
//               </p>
//               <p className="rate-text" style={{ marginTop: "15px" }}>
//                 {oprice} {this.state.toAsset} per {this.state.fromAsset}
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
//   render() {
//     return (
//       <section className="comparison-container">
//         <div className="row">{this.renderFees()}</div>
//       </section>
//     );
//   }
// }

Comparison = reduxForm({
  form: "comparison", // name of the form
})(Comparison);

export default Comparison;
