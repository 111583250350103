import React from "react";
import noFees from "./assets/NoFees.svg";
import noLimits from "./assets/NoLimits.svg";
import noAccounts from "./assets/NoAccount.svg";

function About() {
  return (
    <section className="about">
      <div className="about-wrapper">
        <h2 className="about-tagline bigtitle secondtitle">
          Interchange introduces a new frontier in digital finance.
        </h2>
        <div className="row qa">
          <h3 className="col-md-2 question qanda">About Interchange</h3>
          <div className="answer qanda col-md-5 blurb-a">
            <p>
              Interchange is an application interface that accesses Overline’s
              robust peer to peer digital asset trading network. Interchange
              enables cross-chain trading and interoperability without validator
              nodes or centralization of any kind.
            </p>
          </div>
          <div className="answer qanda col-md-5 blurb-b">
            <p>
              Interchange seeks to stay true to the pioneering ethos behind
              cryptocurrencies by introducing an unstoppable global marketplace
              and putting users back in control of their assets.
            </p>
            <a
              className="link borderless-link-black borderless-link-underlined-black"
              href="https://docs.overline.network/docs/borderless-faq-docs"
            >
              Discover More
            </a>
          </div>
        </div>
        <div className="about-items-outer">
          <div className="about-items">
            <div className="aboutItem">
              <img className="about-item-img" src={noAccounts} alt="" />
              <div className="about-item-content">
                <div className="about-item-title">No Accounts</div>
                <div className="about-item-text">
                  Interchange does not custody any funds or private user data of
                  any kind. Users stay in full control of their assets.
                </div>
              </div>
            </div>
            <div className="aboutItem">
              <img className="about-item-img" src={noLimits} alt="" />
              <div className="about-item-content">
                <div className="about-item-title">No Limits</div>
                <div className="about-item-text">
                  Trading on Interchange is limitless. Users may trade and
                  transact without any daily trade or withdrawal limits.
                </div>
              </div>
            </div>
            <div className="aboutItem">
              <img className="about-item-img" src={noFees} alt="" />
              <div className="about-item-content">
                <div className="about-item-title">No Hidden Fees</div>
                <div className="about-item-text">
                  Only on-chain transaction fees are required. The Interchange
                  itself collects no fees whatsoever.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
