import React from "react";
import BorderlessLogoBlack from "./assets/overline_interchange_black.png";
import BorderlessLogoWhite from "./assets/overline_interchange_white.png";
import CloseIcon from "./assets/mobile_close.svg";

class MobileMenu extends React.Component {
  componentDidMount() {
    let menuIcon = document.querySelector("#menuIcon");
    let nav = document.querySelector(".mobileMenu");

    menuIcon.addEventListener("click", () => {
      if (nav.style.transform != "translateX(0%)") {
        nav.style.transform = "translateX(0%)";
        nav.style.transition = "transform 0.2s ease-out";
      } else {
        nav.style.transform = "translateX(-100%)";
        nav.style.transition = "transform 0.2s ease-out";
      }
    });

    let closeMenu = document.querySelector("#closeIcon");

    closeMenu.addEventListener("click", () => {
      if (nav.style.transform != "translateX(0%)") {
        nav.style.transform = "translateX(0%)";
        nav.style.transition = "transform 0.2s ease-out";
      } else {
        nav.style.transform = "translateX(-100%)";
        nav.style.transition = "transform 0.2s ease-out";
      }
    });
  }
  render() {
    return (
      <div>
        <nav id="navbar">
          <div className="nav-wrapper">
            <div className="logo">
              <a href="#index">
                <img src={BorderlessLogoBlack} alt="Interchange" />
              </a>
            </div>
            <ul id="menu">
              <li>
                <a href="#index">Home</a>
              </li>
              <li>
                <a href="https://docs.overline.network/docs/borderless-faq-docs">
                  About
                </a>
              </li>
              <li>
                <a href="https://overline.network">Overline</a>
              </li>
              <li>
                <a href="https://emb.overline.network">Emblems</a>
              </li>
              <li>
                <a href="https://embnation.com">Community</a>
              </li>
              <li>
                <a href="https://docs.overline.network/docs/beginners-guide-to-mining-block-collider">
                  Start Mining
                </a>
              </li>
              <li>
                <a href="https://docs.overline.network/docs/roadmap">Roadmap</a>
              </li>
              <li>
                <a href="https://confirmsubscription.com/h/t/EEE4979370B32E54">
                  Subscribe
                </a>
              </li>

              {/*}            <li>
                <a href="https://blockcollidertestnet.s3.amazonaws.com/BLOCKCOLLIDER_AT.zip">
                  Download
                </a>
              </li>
    */}
            </ul>
          </div>
        </nav>

        {/* Menu Icon */}
        <div className="menuIcon" id="menuIcon">
          <a className="menu-btn" href="#">
            <span className="icon icon-bars"></span>
            <span className="icon icon-bars overlay"></span>
          </a>
        </div>
        <div className="mobileMenu" style={{ position: "fixed" }}>
          <div className="overlay-logo">
            <a href="#index">
              <img src={BorderlessLogoWhite} alt="Borderless Logo" />
            </a>
          </div>

          <div id="closeIcon" className="menuIcon">
            <a
              className="menu-btn"
              href="#"
              id="closeMenu"
              style={{ top: "18px" }}
            >
              <img src={CloseIcon} alt="Close Icon" height={"18px"} />
            </a>
          </div>

          <div className="overlay-container">
            <div>
              <div className="col-12">
                <ul className="menu-list">
                  <li className="mobile-menu active">
                    <a href="https://borderless.blockcollider.org">Home</a>
                  </li>
                  <li className="mobile-menu">
                    <a href="https://docs.overline.network/docs/borderless-faq-docs">
                      About
                    </a>
                  </li>
                  <li className="mobile-menu">
                    <a href="https://overline.network">Overline</a>
                  </li>
                  <li className="mobile-menu">
                    <a href="https://overline.network">Emblems</a>
                  </li>
                  <li className="mobile-menu">
                    <a href="https://embnation.com">Community</a>
                  </li>
                  <li className="mobile-menu">
                    <a href="https://docs.overline.network/docs/beginners-guide-to-mining-block-collider">
                      Start Mining
                    </a>
                  </li>
                  <li>
                    <a href="https://docs.overline.network/docs/roadmap">
                      Roadmap
                    </a>
                  </li>
                  <li>
                    <a href="https://confirmsubscription.com/h/t/EEE4979370B32E54">
                      Subscribe
                    </a>
                  </li>

                  {/*}               <li className="mobile-menu">
                    <a href="https://blockcollidertestnet.s3.amazonaws.com/BLOCKCOLLIDER_AT.zip">
                      Download
                    </a>
                  </li>
  */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileMenu;
