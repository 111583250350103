import React from "react";

class PlatformComparison extends React.Component {
  constructor() {
    super();
    let descriptions = [
      {
        title: "Non-Custodial",
        description:
          "Users on Interchange remain in complete control of their assets during their trading session. No third party custodian wallets and no representational assets. Trade on Interchange is direct and peer to peer."
      },
      {
        title: "No Limits",
        description:
          "Unlike other major digital asset exchanges, Interchange has no trade minimums, no withdrawal or deposit limits, and no hours of operation. We believe that the limitless possibilities of decentralized trade technology should be just that."
      },
      {
        title: "No Accounts",
        description: `By removing third parties from the trade experience, Interchange does not store or require account information. The platform's session-based, peer to peer approach provides Interchange users freedom from hacks on personal data from centralized servers, all too commonplace in today's financial systems.`
      },
      {
        title: "Open Source",
        description: `Exchanges boasting fee schedules or transparent corporate earnings stop short of providing insight into how the nuts and bolts of the exchange works due to fear of competition. In contrast, each component of Interchange is open source and defined by Timble. There is no back-channel investing, no data abuse, and no black box technology. Every line of Interchange code is transparent and publicly available on day one.`
      },
      {
        title: "Globally Accessible",
        description: `Interchange is built on Overline, a truly decentralized peer to peer network. This means there is no central server or organization that can be manipulated into shutting off or limiting access to the platform, regardless of where users are located.
`
      },
      {
        title: "Scripting Language",
        description: `The native scripting language for trading on Interchange, 'Timble', was created with tomorrow's developers in mind. Interchange enables the next generation of financial technology by simplifying and scaling the complex components of decentralized trade.
`
      }
    ];

    let comparisons = [
      {
        title: "Interchange",
        details: [true, true, true, true, true, true],
        url: "https://blockcollider.org"
      },
      {
        title: "Cosmos",
        details: [true, false, false, true, false, false],
        url: "https://blockcollider.org"
      },
      {
        title: "IDEX",
        details: [false, false, false, false, true, false],
        url: "https://blockcollider.org"
      },
      {
        title: "Binance",
        details: [false, false, false, false, false, false],
        url: "https://blockcollider.org"
      },
      {
        title: "Gemini",
        details: [false, false, false, false, false, false],
        url: "https://blockcollider.org"
      }
    ];

    this.state = { index: 1, descriptions, comparisons };
  }
  componentDidMount() {
    let _this = this;

    setInterval(() => {
      _this.setState({ index: 1 + Math.round(3 * Math.random()) });
    }, 2000);
  }
  render() {
    let ticks = this.state.descriptions.map((d, i) => {
      return (
        <div className="comparison-item">
          {this.state.comparisons.map(({ details }) => (
            <div className={details[i] ? "text-success" : "text-danger"}>
              {details[i] ? `✓` : `X`}
            </div>
          ))}
        </div>
      );
    });

    let mobileTicks = this.state.descriptions.map((d, i) => {
      return (
        <div className="comparison-item">
          {[
            this.state.comparisons[0],
            this.state.comparisons[this.state.index]
          ].map(({ details }) => (
            <div className={details[i] ? "text-success" : "text-danger"}>
              {details[i] ? `✓` : `X`}
            </div>
          ))}
        </div>
      );
    });

    return (
      <section id="comparison">
        <div className="comparison-wrapper">
          <h2 className="about-tagline bigtitle secondtitle">
            Trade as Satoshi would have wanted.
          </h2>
          <p className="comparison-blurb">
            It is time to leave intermediaries and custodians behind and embrace
            a Borderless future. Interchange represents crypto trading as it
            should be, free and uninhibited.
          </p>

          <div className="comparison-table hidden-xs hidden-sm">
            <div className="comparison-exchanges">
              {this.state.descriptions.map(({ title, description }) => (
                <div>
                  {title} ⓘ
                  <span className="comparison-description">{description}</span>
                </div>
              ))}
            </div>
            <div className="comparison-details">
              <div className="comparison-details-header">
                <div style={{ padding: "10px" }}></div>
                {this.state.comparisons.map(({ title, url }) => (
                  <div style={{ paddingBottom: "10px" }}>{title}</div>
                ))}
              </div>
              {ticks}
            </div>
          </div>

          {/* Mobile section hidden on tablet and above */}
          <div className="comparison-table d-md-none">
            <div className="comparison-exchanges">
              {this.state.descriptions.map(({ title, description }) => (
                <div>
                  {title} ⓘ
                  <span className="comparison-description">{description}</span>
                </div>
              ))}
            </div>
            <div className="comparison-details">
              <div className="comparison-details-header">
                <div></div>
                <div>
                  <a
                    target="_blank"
                    rel="noopener"
                    href={this.state.comparisons[0].url}
                  >
                    {this.state.comparisons[0].title}
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    rel="noopener"
                    href={this.state.comparisons[this.state.index].url}
                  >
                    {this.state.comparisons[this.state.index].title}
                  </a>
                </div>
              </div>

              {mobileTicks}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PlatformComparison;
