import React from "react";

class MultichainComparison2 extends React.Component {
  constructor() {
    super();
    let descriptions = [
      {
        title: "Consensus",
        description:
          "A consensus algorithm is the backbone of any blockchain network. The Overline protocol relies on a completely custom-engineered Proof-of-Work algorithm called Proof-of-Distance. All operations on Interchange are processed by Overline miners"
      },
      {
        title: "Validators",
        description:
          "Validators represent special Proof-of-Stake nodes which are afforded elite privileges and yield a return for processing a network's transactions. While validators provide efficiency, they require substantial trade-offs in comparison to Proof-of-Work mining and introduce a significant point of centralization."
      },
      {
        title: "# of Validators",
        description: `Competitor platforms leverage Validator nodes which subject their users to significant regulatory and centralization risks. By leveraging Overline's Proof-of-Distance mining, Interchange achieves cross-chain interoperability with properties of decentralization identical to Bitcoin's battle tested Proof-of-Work mining.`
      },
      {
        title: "Interoperability",
        description: `Overline's Proof-of-Distance consensus algorithm is compatible with all existing Blockchain networks, both public and permissioned (enterprise). Therefore there are no limits to the asset pairings which can be added to the Interchange marketplace.`
      },
      {
        title: "Market Cap",
        description: `Interchange is built by individuals who believe in delivering value, not extracting it. Both Interchange and Overline were built from the ground-up with minimal funding. In doing so Interchange delivers a superior product whilst simultaneously leaving plenty of room for growth for early adopters.
`
      }
    ];

    let comparisons = [
      {
        title: "Overline",
        details: [
          "Proof-of-Distance",
          "Not Required",
          "None",
          "Fully interoperable",
          ""
        ],
        url: "https://blockcollider.org"
      },
      {
        title: "Cosmos",
        details: [
          "Tendermint BFT",
          "Required",
          "100 Validators (Initial)",
          "Requires compatibility",
          "~ $900 Million"
        ],
        url: "https://cosmos.network/"
      },
      {
        title: "Polkadot",
        details: [
          "Delegated Proof-of-Stake (DPoS)",
          "Required",
          "Undisclosed",
          "Requires compatibility",
          "$1.2 Billion"
        ],
        url: "https://polkadot.network/"
      }
    ];

    this.state = { index: 1, descriptions, comparisons };
  }
  componentDidMount() {
    setInterval(() => {
      this.setState({ index: 1 + Math.round(Math.random()) });
    }, 2000);
  }
  render() {
    let ticks = this.state.descriptions.map((d, i) => {
      return (
        <div className="comparison-item">
          {this.state.comparisons.map(({ details }) => (
            <div className={details[i]}>{details[i]}</div>
          ))}
        </div>
      );
    });

    let mobileTicks = this.state.descriptions.map((d, i) => {
      return (
        <div className="comparison-item">
          {[
            this.state.comparisons[0],
            this.state.comparisons[this.state.index]
          ].map(({ details }) => (
            <div className={details[i]}>{details[i]}</div>
          ))}
        </div>
      );
    });

    return (
      <section id="multichaincomparison">
        <div className="multichainWrapper">
          <h2 className="about-tagline bigtitle secondtitle">
            Built using cutting-edge interoperability technology.
          </h2>
          <p className="comparison-blurb">
            Interchange is built on top of the Overline protocol and is
            powered exclusively on-chain by Overline's miners. No
            validators, and no compromises.
          </p>

          <div className="comparison-table hidden-xs hidden-sm">
            <div className="comparison-exchanges">
              {this.state.descriptions.map(({ title, description }) => (
                <div>
                  {title} ⓘ
                  <span className="comparison-description">{description}</span>
                </div>
              ))}
            </div>
            <div className="comparison-details">
              <div className="comparison-details-header">
                <div style={{ padding: "10px" }}></div>
                {this.state.comparisons.map(({ title, url }) => (
                  <div style={{ paddingBottom: "10px" }}>
                    <a target="_blank" rel="noopener" href={url}>
                      {title}
                    </a>
                  </div>
                ))}
              </div>
              {ticks}
            </div>
          </div>

          {/* Mobile section hidden on tablet and above */}
          <div className="comparison-table d-md-none">
            <div className="comparison-exchanges">
              {this.state.descriptions.map(({ title, description }) => (
                <div>
                  {title} ⓘ
                  <span className="comparison-description">{description}</span>
                </div>
              ))}
            </div>
            <div className="comparison-details">
              <div className="comparison-details-header">
                <div></div>
                <div>
                  <a
                    target="_blank"
                    rel="noopener"
                    href={this.state.comparisons[0].url}
                  >
                    {this.state.comparisons[0].title}
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    rel="noopener"
                    href={this.state.comparisons[this.state.index].url}
                  >
                    {this.state.comparisons[this.state.index].title}
                  </a>
                </div>
              </div>
              {mobileTicks}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default MultichainComparison2;
