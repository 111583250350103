import { combineReducers } from 'redux';
// import { reducer as form } from 'redux-form';
//represents the balances
// import price from './price';
// import orderbook from './orderbook';
// import passport from './passport';
// import pending from './pending';
// import settings from './settings';
// import {reducer as socket} from './socket';
// import transfer_history from './transfer_history';
// import ui_marketplace from './ui_marketplace';
// import ui_passport from './ui_passport';
// import scheduled_events from './scheduled_events';
// import mempool from './mempool';

const rootReducer = combineReducers({
  // form,
  // mempool,
  // price,
  // orderbook,
  // passport,
  // pending,
  // settings,
  // socket,
  // transfer_history,
  // scheduled_events,
  // ui_marketplace,
  // ui_passport
});

export default rootReducer;
