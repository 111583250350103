import React, { Component } from "react";

export default class EthUsdtWidget extends Component {
  componentDidMount() {
    if (document.getElementById("ethusdt-widget")) {
      let newDoc = document.createElement("div");
      newDoc.innerHTML = document.getElementById("ethusdt-widget").innerHTML;
      document.getElementById("ethusdt-container").appendChild(newDoc);
      newDoc.style.display = "block";
    }
  }
  render() {
    return <div id="ethusdt-container" className="minWidth"></div>;
  }
}
