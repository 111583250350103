import React from "react";
import DevelopersSVG from "./assets/Developers.svg";

/* Make sure to clean up image paths and href links */

function DeveloperAPI() {
  return (
    <section id="developers">
      <div className="developer-api">
        <img className="api-img" src={DevelopersSVG} alt="" />
        <div className="developer-api-content">
          <div className="api-title">
            Developers, developers, developers, developers!
          </div>
          <div className="api-text">
            Explore our developer documentation to learn more about Interchange'
            custom scripting language 'Timble'
          </div>
          <a href="https://docs.overline.network/docs" className="btn btn-1">
            <svg>
              <rect x="0" y="0" fill="none" width="100%" height="100%" />
            </svg>
            Documentation
          </a>
        </div>
      </div>
    </section>
  );
}

export default DeveloperAPI;
