import {
  humanToInternalAsBN,
  COIN_FRACS,
  internalToBN,
  internalBNToHuman,
  Currency,
  CurrencyInfo,
} from "bc-sdk/dist/utils/coin";
import { Decimal } from "decimal.js";

var Web3 = require("web3");
const BN = require("bn.js");

//URLS
export const mainnet_url =
  "https://mainnet.infura.io/v3/97c8bf358b9942a9853fab1ba93dc5b3";
export const multiverseUrl =
  "http://multiverse.blockcollider.org/#/blocks/latest";

//WEB3
export const web3 = new Web3(new Web3.providers.HttpProvider(mainnet_url));

export const eIds = [
  "DNCWUIR9GBCS7DIERYIIWPZ9Z2117IJ9ZZ",
  "548Q8A315UV37WFIYVDNCS32847ZJVRZ7F",
  "2WFAAHGVRVJIEFT9IJC8ENS6Z36AB8X5D1",
  "Q1HGXX6UDA1U8AVKZ1S6HHH32D99VJH7CQ",
  "KKCRSY1MNV21HCZZS6M7DQEHDU8SGA85Q3",
  "ZKD7XVVFSWG2GQ7AV2KABV8JXMIDWS8P3B",
  "UX83AVZXV9AYT6NRYMVIQMPB6I6I1D38FV",
  "DJV72718MY7XV8EMXTUY6DM1KCV2C6X14T",
  "P7JFS2YI6MNZFVY95FDMV45EGIX6F1BPAV",
  "GMGAYV28HNBZSAHUQQD3PQDXMFGZU7BMBP",
  "SQDE7E4HMPT3J4DECSY4IZ3HP1PC4MANB7",
  "9D13ZE7XSBTJ94N9BNJ2MA33VMAY2YPIRB",
  "BZ34DW4M5J6XZIQV5DWBCdddd2MJV32V955Q1H",
  "8FWC8GZWSE8SJKY7NBSE77XER4KQ8NXK1Z",
  "QQBE2GAH8MKB8XVHI8PEYZCXTIEWF4Z11D",
  "JSE9SJQSPMXWUHQ6PII59N6NMHEB9EPKAJ",
  "9PFG6U3B5UWDIEMYKP6B91EHZ7KB1A9YPD",
  "AYVQKI2AW5TZCHII5KIVB5N6QQIY1MM1Y9",
  "FWI7VM1XS26DR69JZIAFSPQQGCEXMS14FA",
  "IKKPZRZQD3FJ88KSBBQFT4Q7Z9Q8ME6UHC",
  "SYZPKC5JIC5HQWXMWJEN2Y9Z2CAEJRQKID",
];

export const ids = [
  "ef5f6b3377d44afc8b284d6aa6033a6f",
  "50dd475d95914816b5fe67dc424c4bad",
  "6214eb6580bf49d485ed94417272640f",
  "3a0236e62e3948a9b234f5602c9e03ac",
  "3a97eb63ed614a8387b71e2bcab064c1",
  "188e85dbdca345d29721f493859b006b",
  "ce84ef348cab4faa9f4e7c854f608555",
  "ac42f1c8b94e47da954c6550a7b582a6",
  "7a122e147c034cd49a4975ae8878655b",
  "475381ae3f0445ceb2233f949d500aec",
  "fd5e7ae90b3a4bf5968fb909257fff40",
  "bd736617d5d04e708cf6d20b466b995b",
  "e4e8303c8f8c41c687cddf4373f64f01",
  "208c93f945a3495ebb9f42516bf63ad0",
  "b18237b9dac64c22a0ded9817774f4cb",
  "56b202e6834a4f5ab47eb163551cc991",
  "aae42c0e49f84fe7b90fdcdd0986acb8",
  "a5df6a93ae4f460e972e04fa5398d157",
  "d31d552b4e5d41b5b34aec1310fbdf8f",
  "ff49fc5d6a654d2fad397b69912d4e3e",
  "09f371bbdbe94b0b9bb684a0cf20dfa3",
  "d1e85bc998be4291a691f223978d2d4b",
  "e4aa52bf76a948ea92ae7772d299aef0",
  "b6b445ca6dbc424f9a9309cb14ddae5d",
  "a1743f084f8a46bfb3696389eeb9f217",
  "cad7f83b4e47462e90387487530239af",
  "01e4876c179a49ebbf8ad09f7037d9ee",
  "483c1730b99b46729c7f82f49302bbf8",
  "fa3e2193dfcb48978f731fadf8a1282a",
  "139b233124ca4a7cb78ac63cd0a2d29f",
  "e846fc35019a4766babcc4e9e757bb74",
  "b838cc16e73f482b960d1f86c05533a6",
  "4ca56581b1234f2a9cf4b7333c1f8ac1",
  "d266a83cc83a40d7b14257be4579d310'",
  "eeb4395af38744ce9129d8975781d34e",
  "baba69547b5049d687d12db75d58431a",
  "e674ba0fd9dd4a78b50ed333a3198c64",
  "e1967f2f27e143c3b8831d0e612bc7b1",
  "42e30346ab5d41c7850f45adedfc9db2",
  "e5a3e9ed05704633b5807ca180e71f16",
  "d10118fb82354e38aa7e18bf306bd82a",
  "ef5f6b3377d44afc8b284d6aa6033a6f",
  "50dd475d95914816b5fe67dc424c4bad",
  "6214eb6580bf49d485ed94417272640f",
  "3a0236e62e3948a9b234f5602c9e03ac",
  "3a97eb63ed614a8387b71e2bcab064c1",
  "188e85dbdca345d29721f493859b006b",
  "ce84ef348cab4faa9f4e7c854f608555",
  "ac42f1c8b94e47da954c6550a7b582a6",
  "7a122e147c034cd49a4975ae8878655b",
  "475381ae3f0445ceb2233f949d500aec",
  "fd5e7ae90b3a4bf5968fb909257fff40",
  "bd736617d5d04e708cf6d20b466b995b",
  "e4e8303c8f8c41c687cddf4373f64f01",
  "208c93f945a3495ebb9f42516bf63ad0",
  "b18237b9dac64c22a0ded9817774f4cb",
  "56b202e6834a4f5ab47eb163551cc991",
  "aae42c0e49f84fe7b90fdcdd0986acb8",
  "a5df6a93ae4f460e972e04fa5398d157",
  "d31d552b4e5d41b5b34aec1310fbdf8f",
  "ff49fc5d6a654d2fad397b69912d4e3e",
  "09f371bbdbe94b0b9bb684a0cf20dfa3",
  "d1e85bc998be4291a691f223978d2d4b",
  "e4aa52bf76a948ea92ae7772d299aef0",
  "b6b445ca6dbc424f9a9309cb14ddae5d",
  "a1743f084f8a46bfb3696389eeb9f217",
  "cad7f83b4e47462e90387487530239af",
  "01e4876c179a49ebbf8ad09f7037d9ee",
  "483c1730b99b46729c7f82f49302bbf8",
  "fa3e2193dfcb48978f731fadf8a1282a",
  "139b233124ca4a7cb78ac63cd0a2d29f",
  "e846fc35019a4766babcc4e9e757bb74",
  "b838cc16e73f482b960d1f86c05533a6",
  "4ca56581b1234f2a9cf4b7333c1f8ac1",
  "d266a83cc83a40d7b14257be4579d310'",
  "eeb4395af38744ce9129d8975781d34e",
  "baba69547b5049d687d12db75d58431a",
  "e674ba0fd9dd4a78b50ed333a3198c64",
  "e1967f2f27e143c3b8831d0e612bc7b1",
  "42e30346ab5d41c7850f45adedfc9db2",
  "e5a3e9ed05704633b5807ca180e71f16",
  "d10118fb82354e38aa7e18bf306bd82a",
  "854db40932f949039d2f3c314816b614",
  "97c8bf358b9942a9853fab1ba93dc5b3",
  "e1967f2f27e143c3b8831d0e612bc7b1",
  "42e30346ab5d41c7850f45adedfc9db2",
  "e5a3e9ed05704633b5807ca180e71f16",
  "bda837d7e4044d6c8b5b89691c9e262e",
  "a95781685090488a83012e1c37e9728b",
  "2e6d9331f74d472a9d47fe99f697ca2b",
  "f315575765b14720b32382a61a89341a",
  "3bbb5ebeb45e4b2b9a35261f272fb611",
  "e58d5891ebaf464dbcad6a926a107adf",
  "c334bb4b45a444979057f0fb8a0c9d1b",
  "781c281ea82347709935f5b394f2383c",
  "6d7880a8f4b347ca8953d2715e164241",
  "80311d26ccb74946b9d016f38ce901d2",
  "640777fe168f4b0091c93726b4f0463a",
  "8e609c76fce442f8a1735fbea9999747",
  "607a7dfcb1ad4a0b83152e30ce20cfc5",
  "bb136bc2d97342adaaf1c5872a2334ed",
  "da3717f25f824cc1baa32d812386d93f",
  "60522bb7c60d4cb5bb0f694ef0123ee0",
  "4876e0df8d31475799c8239ba2538c4c",
  "e8360842612d479483c14d2ab28dafbe",
  "1ed0d8f7f63e444a9eb73cb738b8c771",
  "6cbcb14ceae14decac19c71afc7c6c5d",
  "c3b0f4694b7a4e6aaf7f474e9522fa9c",
  "f039330d8fb747e48a7ce98f51400d65",
  "58073b4a32df4105906c702f167b91d2",
  "76fb6c10f1584483a45a0a28e91b07ad",
  "25c7c08910c04b0c9be79c09f559652e",
  "dc22c9c6245742069d5fe663bfa8a698",
  "eeb4395af38744ce9129d8975781d34e",
  "baba69547b5049d687d12db75d58431a",
  "e674ba0fd9dd4a78b50ed333a3198c64",
  "917eab7f24e645509c93f104186522e5",
  "aa7a461490244b65a54e2f67b959fa8a",
  "ab03d6233a77417db2b4dcc40884e4af",
  "6d816194aab74348b53e35232232a260",
  "06fd041b5d42478cb9d9b68b35069d85",
  "498494c790964af8be6eafe6e2cdffec",
  "326b0d7561824e0b8c4ee1f30e257019",
  "419c7f687f3b41f0bc9e0e647b9ab911",
  "4b2dbe30a1b1404e80749545bf913be9",
  "ef4d9142fb914a998dcabfcbddda8bdf",
  "7a153118eae44f8d808da67bb449362d",
  "19de6a4c0ccb416287b18bc81fa0b3b1",
  "94cc5e7210024cbda2686a62ae4e267a",
  "ff9137b012f144d7996e8957fb2f5b26",
  "22b2ebe2940745b3835907b30e8257a4",
  "48899b10645a48e189e345be4be19ece",
  "b58b6c88761446ec82f70ebc965c90e8",
  "7d0d81d0919f4f05b9ab6634be01ee73",
  "ed0e4d0e188240e7a0eeb68664f4787e",
  "26b896d72b16489a8927ee528b990e9f",
  "ef95db49a6254b6ea1fdcf98a1a620ca",
  "43dd12c4245b4924b4a29cea5afa18ef",
  "cf23197dd7954528a7b3adb180dd9995",
  "69500eb24e3a4bd79fbb8cd1fbb9a3ef",
  "7818d1d2356a45e68b03b25a5ad8052c",
  "5ffc47f65c4042ce847ef66a3fa70d4c",
  "f20390fe230e46608572ac4378b70668",
  "906693e94763465a9809e361fb530c87",
  "349e6679b09e46caae9cfa4337a22701",
  "97524564d982452caee95b257a54064e",
  "5fc47c37ebd24bc68c4f203742da9752",
  "19ae29fc531e46229a7dec1f77e1d067",
  "5bfa6b51715c4ee1a18c14364bfc8e13",
  "9bb3633d970044baa92a78ff9d26c8bd",
  "c8d36b72d2d04f16a94931809cdf6383",
  "32ed0b70fae34f309433209ac53794a9",
  "84294a5b020c4ab2990bdb28fb553297",
  "6b0f8cad403b42cabb4cdb1e62017d24",
  "18f52ed927a64f57ad1810599a18e209",
  "4e987044d78c44319523b16c7bf0412c",
  "698185618aa64a9f918c9bf9590520bd",
  "afb899cc7918472e9c54417ec13b6c39",
  "e65462856a9b46fbbec5d0fde337f2b0",
  "fb3eae44a02f4a37868d3cd0ba40f30f",
  "668b3268d5b241b5bab5c6cb886e4c61",
  "abbfbb3d267b4bc882ff8a1080a2a0f6",
  "15fe1eff1ac44d5b995232621c794ce4",
  "a2687d7078ff46d3b5f3f58cb97d3e44",
  "eb41d8eca36649e1a68a61e3f233e55c",
  "37efa691ffec4c41a60aa4a69865d8f6",
  "b05f5370e790473c99b74bed60d69162",
  "efaece4f5f4443979063839c124c8171",
  "eaf5e0b4a01042a48211762c8d4eec44",
  "4161ec5436de4608be5ddf1101c1d0a5",
  "b24ba38418084f5c974b3af71bd63117",
  "bbfb2fa1009e40e4b72b10166d9a5069",
  "e057d93273374902ae044401f8099775",
  "d25de4d32b0f48a6bc289cfc7d50d7fd",
  "006a20906c5c42f5a009c20d63988dd7",
  "8fa5e8ad9f914ec1867eadfbf93b1de0",
  "8831d56e946146a5bf2d5df68f034ee3",
  "d356a5affcea4eec8e0cdde7e52570ba",
  "624bfd9b2a084cd39ca8e1f50d79f56e",
  "fb32a606c5c646c7932e43cfaf6c39df",
  "2cf3ec9bd42d4099b8620c2a6ee8c51a",
  "e49eab7ebf6b47688265ad5e5c0aacd0",
  "e8235710b20f4f039a994b4f39c0d01f",
  "f8c3858f892d4199840f5354cc954713",
  "405d90835a22404f91c36993be519dc4",
  "0ecc3584199b4bdeb1c98d5c6e781818",
  "26a5f300569d4e938e10e3abd2caef74",
  "36b98a13557c4b8583d57934ede2f74d",
  "7bbf73a8855d4c0491f93e6dc498360d",
  "453d2049c15d4a8da5501a0464fa44f8",
  "c73ecd76112b419ab2766b7c7f3e233e",
  "cfac36101869499f961b31585449de88",
  "16dfd66c4a1a4a9da5724254c97c93e6",
  "6a6c66740e9e4cea9cc8425869e9d106",
];

// CONTRACTS
export const LEMB_ADDRESS = "0xcA8FdddC496E028708Cf9d5aad0031c5b37b760d";

export const EMB_ADDRESS = "0xdb0acc14396d108b3c5574483acb817855c9dc8d";

export const LEASE_ADDRESS = "0x32CD3B832075195164E7dDcE2D1C580Ce80F029b";
export const EXISTING_LEASE_ADDRESS =
  "0xb19188528FD972318AAE16C739849627B758474a";
export const EMB_EXCHANGE_ADDRESS =
  "0x41538bef42ad5D7683D2e6d4b6CeefEb29F4F036";
export const VANITY_EXCHANGE_ADDRESS =
  "0x42E62cA948354F4B63d776a03d87C98F2c71Be42";
export const USDT_ADDRESS = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
export const DAI_ADDRESS = "0x6b175474e89094c44da98b954eedeac495271d0f";

export const EMB_ABI = require("./contracts/Emblem.json").abi;
export const DAI_ABI = require("./contracts/DAI.json");
export const USDT_ABI = require("./contracts/USDT.json");

let options = { gasLimit: 200000, gasPrice: 20000 };
export const DAI = new web3.eth.Contract(DAI_ABI, DAI_ADDRESS, options);
export const USDT = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS, options);
export const EMB = new web3.eth.Contract(EMB_ABI, EMB_ADDRESS, options);

export const shortenHash = function (hash, partLength = 5) {
  if (hash)
    return (
      hash.slice(0, partLength) +
      "..." +
      hash.slice(hash.length - partLength, hash.length)
    );
  else return "";
};

export const ROVER_SECONDS_PER_BLOCK = {
  emb: 6.5,
  nrg: 6.5,
  bc: 6.5,
  btc: 600.0,
  eth: 15.0,
  dai: 15.0,
  usdt: 15.0,
  xaut: 15.0,
  lsk: 10.0,
  neo: 15.0,
  wav: 65.0,
};

export function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

export const tokenDictionary = {
  emb: "eth",
  nrg: "bc",
  btc: "btc",
  eth: "eth",
  dai: "eth",
  neo: "neo",
  wav: "wav",
  lsk: "lsk",
  usdt: "eth",
  xaut: "eth",
};

export const fee = humanToInternalAsBN("0.0006806", COIN_FRACS.NRG);

window.getStorage = function (key) {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (err) {
    return null;
  }
};

//SETTINGS
export const settings = window.getStorage("settings")
  ? window.getStorage("settings")
  : {
      autoTrader: false,
      shiftMaker: 1,
      shiftTaker: 1,
      minOrder: 1,
      deposit: 109,
      confirmation: 10,
      settlement: 450,
      manualConnect: false,
      autoConnect: false,
      hostname: "",
      durView: "blocks",
      port: "",
      scookie: "",
    };

export const txURL = {
  // NRG:'http://borderless.ngrok.io/#/tx/',
  EMB: "https://etherscan.io/tx/",
  BTC: "https://blockchair.com/bitcoin/transaction/",
  ETH: "https://etherscan.io/tx/",
  DAI: "https://etherscan.io/tx/",
  USDT: "https://etherscan.io/tx/",
  NEO: "https://neoscan.io/transaction/",
  WAV: "https://wavesexplorer.com/tx/",
  LSK: "https://explorer.lisk.io/tx/",
};

export const blockURL = {
  // NRG:'http://multiverse.blockcollider.com/block/',
  BTC: "https://blockchair.com/bitcoin/block/",
  ETH: "https://etherscan.io/block/",
  NEO: "https://neoscan.io/block/",
  WAV: "https://wavesexplorer.com/blocks/",
  LSK: "https://explorer.lisk.io/block/",
};

export const colors = {
  green: "#64ad62",
  red: "#de7272",
  "L-EMB": "#9d8ec4",
  EMB: "#f6c054",
  NRG: "#20a9bc",
  BTC: "#FBB02F",
  ETH: "#627eea",
  USDT: "#27a17b",
  DAI: "#ffc926",
  NEO: "#5ABD02",
  WAV: "#0355ff",
  LSK: "#073878",
};

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const fromUnit = (type, amount) => {
  if (type === "btc" || type === "wav" || type === "lsk" || type === "emb")
    return new Decimal(amount).div(new Decimal(Math.pow(10, 8))).toString();
  else if (type === "eth" || type === "nrg" || type === "dai")
    return new Decimal(amount).div(new Decimal(Math.pow(10, 18))).toString();
  else if (type === "usdt")
    return new Decimal(amount).div(new Decimal(Math.pow(10, 6))).toString();
  else return amount;
};

export const multiplyCurrency = (curr1, curr2) => {
  let unit1 = Currency.toMinimumUnitAsStr(
    curr1.type.toLowerCase(),
    curr1.amount,
    CurrencyInfo[curr1.type.toLowerCase()].humanUnit
  );
  let unit2 = Currency.toMinimumUnitAsStr(
    curr2.type.toLowerCase(),
    curr2.amount,
    CurrencyInfo[curr2.type.toLowerCase()].humanUnit
  );

  let mult = new BN(unit1).mul(new BN(unit2));

  mult = Currency.fromMinimumUnitToHuman(
    curr1.type.toLowerCase(),
    mult.toString(),
    CurrencyInfo[curr1.type.toLowerCase()].minUnit
  );

  mult = Currency.fromMinimumUnitToHuman(
    curr2.type.toLowerCase(),
    mult,
    CurrencyInfo[curr2.type.toLowerCase()].minUnit
  );
  return mult;
};

export const unit = (type) => {
  if (type === "btc" || type === "wav" || type === "lsk" || type === "emb")
    return 8;
  else if (type === "eth" || type === "nrg" || type === "dai") return 18;
  else if (type === "usdt") return 6;
  else return 0;
};

export const toUnit = (type, amount) => {
  return new Decimal(amount)
    .mul(new Decimal(Math.pow(10, unit(type))))
    .toString();
};

//PAIRS
export const assets = [
  "NRG",
  "EMB",
  "L-EMB",
  "USDT",
  "DAI",
  "BTC",
  "ETH",
  "NEO",
  "WAV",
  "LSK",
];

export const pairs = {
  "bid/ask": {
    "USDT/EMB": {
      asset: "USDT",
      denomination: "EMB",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "DAI/EMB": {
      asset: "DAI",
      denomination: "EMB",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "BTC/EMB": {
      asset: "BTC",
      denomination: "EMB",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "ETH/EMB": {
      asset: "ETH",
      denomination: "EMB",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "NEO/EMB": {
      asset: "NEO",
      denomination: "EMB",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "WAV/EMB": {
      asset: "WAV",
      denomination: "EMB",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "LSK/EMB": {
      asset: "LSK",
      denomination: "EMB",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "DAI/USDT": {
      asset: "DAI",
      denomination: "USDT",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "BTC/USDT": {
      asset: "BTC",
      denomination: "USDT",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "ETH/USDT": {
      asset: "ETH",
      denomination: "USDT",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "NEO/USDT": {
      asset: "NEO",
      denomination: "USDT",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "WAV/USDT": {
      asset: "WAV",
      denomination: "USDT",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "LSK/USDT": {
      asset: "LSK",
      denomination: "USDT",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "BTC/DAI": {
      asset: "BTC",
      denomination: "DAI",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "ETH/DAI": {
      asset: "ETH",
      denomination: "DAI",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "NEO/DAI": {
      asset: "NEO",
      denomination: "DAI",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "WAV/DAI": {
      asset: "WAV",
      denomination: "DAI",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "LSK/DAI": {
      asset: "LSK",
      denomination: "DAI",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "ETH/BTC": {
      asset: "ETH",
      denomination: "BTC",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "NEO/BTC": {
      asset: "NEO",
      denomination: "BTC",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "WAV/BTC": {
      asset: "WAV",
      denomination: "BTC",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "LSK/BTC": {
      asset: "LSK",
      denomination: "BTC",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "NEO/ETH": {
      asset: "NEO",
      denomination: "ETH",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "WAV/ETH": {
      asset: "WAV",
      denomination: "ETH",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "LSK/ETH": {
      asset: "LSK",
      denomination: "ETH",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "WAV/NEO": {
      asset: "WAV",
      denomination: "NEO",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "LSK/NEO": {
      asset: "LSK",
      denomination: "NEO",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "LSK/WAV": {
      asset: "LSK",
      denomination: "WAV",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
  },
  leases: {
    "New Leases": {
      asset: "L-EMB",
      denomination: "ETH",
      payment: "smartContract",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: true,
    },
    "Existing Leases": {
      asset: "L-EMB",
      denomination: "ETH",
      payment: "smartContract",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: false,
    },
  },
  realestate: {
    "Shark/BTC": {
      asset: "Shark",
      denomination: "BTC",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: false,
    },
    "111/BTC": {
      asset: "111",
      denomination: "BTC",
      payment: "collateral",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: false,
    },
  },
  auction: {
    "EMB/NRG": {
      asset: "EMB",
      denomination: "NRG",
      payment: "fixedFee",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: false,
    },
    "USDT/NRG": {
      asset: "USDT",
      denomination: "NRG",
      payment: "fixedFee",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: false,
    },
    "DAI/NRG": {
      asset: "DAI",
      denomination: "NRG",
      payment: "fixedFee",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: false,
    },
    "BTC/NRG": {
      asset: "BTC",
      denomination: "NRG",
      payment: "fixedFee",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: false,
    },
    "ETH/NRG": {
      asset: "ETH",
      denomination: "NRG",
      payment: "fixedFee",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: false,
    },
    "NEO/NRG": {
      asset: "NEO",
      denomination: "NRG",
      payment: "fixedFee",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: false,
    },
    "WAV/NRG": {
      asset: "WAV",
      denomination: "NRG",
      payment: "fixedFee",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: false,
    },
    "LSK/NRG": {
      asset: "LSK",
      denomination: "NRG",
      payment: "fixedFee",
      bids: [],
      asks: [],
      highBid: 0,
      lowAsk: 0,
      pastOrders: [],
      lastPrice: { time: new Date(0), price: 0 },
      firstPrice: { time: new Date(), price: 0 },
      spread: 0,
      volume: 0,
      buySide: true,
      sellSide: false,
    },
  },
};
