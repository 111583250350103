import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import XautWidget from "./widgets/XautWidget";
import EthBtcWidget from "./widgets/EthBtcWidget";
import EthUsdtWidget from "./widgets/EthUsdtWidget";
import BtcUsdtWidget from "./widgets/BtcUsdtWidget";
import XautUstWidget from "./widgets/XautUstWidget";
import DaiBtcWidget from "./widgets/DaiBtcWidget";

export default class Pairs extends Component {
  render() {
    return (
      <div className="pairs-container">
        <Row className="pairs-row">
          <Col className="pair-col" sm={12} lg={4} style={{ padding: 15 }}>
            <div>
              <p className="pair-name">Bitcoin / USD (Tether)</p>
              <BtcUsdtWidget className="widget-item" />
            </div>
          </Col>
          <Col className="pair-col" sm={12} lg={4} style={{ padding: 15 }}>
            <div>
              <p className="pair-name">Gold / USD (Tether)</p>
              <XautUstWidget />
            </div>
          </Col>
          <Col className="pair-col" sm={12} lg={4} style={{ padding: 15 }}>
            <div>
              <p className="pair-name">Ethereum / USD (Tether)</p>
              <EthUsdtWidget />
            </div>
          </Col>
        </Row>
        <Row className="pairs-row">
          <Col className="pair-col" sm={12} lg={4} style={{ padding: 15 }}>
            <div>
              <p className="pair-name">Ethereum / Bitcoin</p>
              <EthBtcWidget />
            </div>
          </Col>
          <Col className="pair-col" sm={12} lg={4} style={{ padding: 15 }}>
            <div>
              <p className="pair-name">Gold / Bitcoin</p>
              <XautWidget />
            </div>
          </Col>
          <Col className="pair-col" sm={12} lg={4} style={{ padding: 15 }}>
            <div>
              <p className="pair-name">Dai / Bitcoin</p>
              <DaiBtcWidget />
            </div>
          </Col>
        </Row>

        {/* <Row className="pairs-row">
          <Col className="pair-col" sm={12} lg={4} style={{ padding: 15 }}>
            <p className="pair-name">Bitcoin / USD (Tether)</p>
            <BtcUsdtWidget />
          </Col>
          <Col className="pair-col" sm={12} lg={4} style={{ padding: 15 }}>
            <p className="pair-name">Gold / USD (Tether)</p>
            <XautUstWidget />
          </Col>
          <Col className="pair-col" sm={12} lg={4} style={{ padding: 15 }}>
            <p className="pair-name">Ethereum / USD (Tether)</p>
            <EthUsdtWidget />
          </Col>
        </Row>
        <Row className="pairs-row">
          <Col className="pair-col" sm={12} lg={4} style={{ padding: 15 }}>
            <p className="pair-name">Ethereum / Bitcoin</p>
            <EthBtcWidget />
          </Col>
          <Col className="pair-col" sm={12} lg={4} style={{ padding: 15 }}>
            <p className="pair-name">Gold / Bitcoin</p>
            <XautWidget />
          </Col>
          <Col className="pair-col" sm={12} lg={4} style={{ padding: 15 }}>
            <p className="pair-name">Dai / Bitcoin</p>
            <DaiBtcWidget />
          </Col>
        </Row> */}
      </div>
    );
  }
}
