import React from "react";
import BorderlessLogoWhite from "./assets/overline_interchange_white.png";

/* Make sure to clean up image paths and href links */

function Footer() {
  return (
    <footer>
      <div className="pre-footer">
        <div className="pre-footer-grid">
          <div>
            <div className="pre-footer-tagline">
              Ready for Financial Freedom?
            </div>
            <div className="pre-footer-subheading">
              Download the Interchange application for free and start trading
              today. No account needed.
            </div>
          </div>
          <div className="pre-footer-buttons">
            {/*} <a
              href="https://borderless.blockcollider.org"
              target="_blank"
              rel="noopener noreferrer"
              className="button-outline-white"
            >
              <div className="button-content">Download</div>
              <svg width="22" height="22" className="button-icon">
                <path
                  fill="currentColor"
                  d="M7 4.5l4.75 6.5L7 17.5h2.5L14 11 9.5 4.5z"
                >
                  {" "}
                </path>
              </svg>
  </a> */}
            {/* Old Footer Button
            <a
              className="button-outline-white"
              href="https://borderless.blockcollider.org"
            >
              <div className="button-content">Start Mining</div>
              <svg width="22" height="22" className="button-icon">
                <path
                  fill="currentColor"
                  d="M7 4.5l4.75 6.5L7 17.5h2.5L14 11 9.5 4.5z"
                ></path>
              </svg>
            </a>
            */}

            <a
              href="https://docs.overline.network/docs/beginners-guide-to-mining-block-collider"
              className="btn btn-2 footer-btn"
            >
              <svg>
                <rect x="0" y="0" fill="none" width="100%" height="100%" />
              </svg>
              Start Mining
            </a>
            <a
              href="https://docs.overline.network/docs/how-do-i-get-emblems-nrg"
              className="btn btn-2 footer-btn"
            >
              <svg>
                <rect x="0" y="0" fill="none" width="100%" height="100%" />
              </svg>
              Buy Emblems
            </a>
          </div>
        </div>
      </div>
      {/* Need to specify image path */}
      <div className="main-footer">
        <div className="footer-grid">
          {" "}
          <img
            src={BorderlessLogoWhite}
            alt="Borderless Services Inc."
            className="footer-logo"
            height="50"
          />
          <div className="footer-gap"></div>
          <div>
            <div className="footer-title">DISCOVER</div>
            <a
              className="footer-link borderless-link-underlined-footer"
              href="https://docs.overline.network/docs/interchange-faq-docs"
            >
              FAQ
            </a>
            <a
              className="footer-link borderless-link-underlined-footer"
              href="https://overline.network/"
            >
              Overline
            </a>
            <a
              className="footer-link borderless-link-underlined-footer"
              href="https://docs.overline.network/docs/beginners-guide-to-mining-overline"
            >
              Mining
            </a>
            <a
              className="footer-link borderless-link-underlined-footer"
              href="https://docs.overline.network/docs/how-do-i-get-emblems-and-overline"
            >
              Emblems
            </a>
          </div>
          <div className="footer-gap"></div>
          <div>
            <div className="footer-title">DEVELOPER</div>
            <a
              className="footer-link borderless-link-underlined-footer"
              href="https://docs.overline.network/docs/rpc-api"
            >
              API
            </a>
            {/*} <a
              className="footer-link borderless-link-underlined-footer"
              href="https://borderless.blockcollider/SDK"
            >
              SDK
</a> */}
            <a
              className="footer-link borderless-link-underlined-footer"
              href="https://docs.overline.network/docs/timble"
            >
              Documentation
            </a>
            <a
              className="footer-link borderless-link-underlined-footer"
              href="http://explorer.overline.network"
            >
              Block Explorer
            </a>
          </div>
          <div className="footer-gap"></div>
          <div>
            <div className="footer-title">COMMUNITY</div>
            <a
              className="footer-link borderless-link-underlined-footer"
              href="https://twitter.com/@overlinenetwork"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
            <a
              className="footer-link borderless-link-underlined-footer"
              href="https://t.me/overlinenetwork"
              target="_blank"
              rel="noopener noreferrer"
            >
              Telegram
            </a>
            <a
              className="footer-link borderless-link-underlined-footer"
              href="https://blog.blockcollider.org/"
            >
              Medium
            </a>
            <a
              className="footer-link borderless-link-underlined-footer"
              href="https://www.embnation.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              EMB Nation
            </a>
          </div>
          <div className="footer-gap"></div>
          <div className="footer-copyright">
            © 2020 Borderless Services Inc.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
