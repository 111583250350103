import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import "../styles/hero-styles.css";
var platform = require('platform');

function VerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ marginLeft: "auto", marginRight: "auto" }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <h1 color="#000">Interchange Beta Access</h1>
            <form
              class="js-cm-form"
              id="subForm"
              action="https://www.createsend.com/t/subscribeerror?description="
              method="post"
              data-id="2BE4EF332AA2E32596E38B640E9056190FB4F7198C9F457AE1A7583BF662FA584AE64ACE2B68C9612722E52E313615828A17F5C8C7F30C16B39BC8AF11B633F0"
            >
              <div>
                <div>
                  <label>Email </label>
                  <input
                    autocomplete="Email"
                    aria-label="Email"
                    class="js-cm-email-input qa-input-email"
                    id="fieldEmail"
                    maxlength="200"
                    name="cm-yhqhyl-yhqhyl"
                    required=""
                    type="email"
                  />
                </div>
              </div>
              <button type="submit">Subscribe</button>
            </form>
          </div>
          <script
            type="text/javascript"
            src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"
          ></script>
          {/* <form
            action="https://www.createsend.com/t/subscribeerror?description="
            method="post"
            data-id="2BE4EF332AA2E32596E38B640E9056190FB4F7198C9F457AE1A7583BF662FA584AE64ACE2B68C9612722E52E313615828A17F5C8C7F30C16B39BC8AF11B633F0"
            class="js-cm-form"
            id="subForm"
          >
            <div>
              <div>
                <label>Email</label>
                <input
                  aria-autocomplete="Email"
                  aria-label="Email"
                  maxLength="200"
                  required=""
                  type="email"
                  lass="js-cm-email-input qa-input-email"
                  id="fieldEmail"
                />
                <button type="submit">Submit</button>
              </div>
            </div>
            <script
              type="text/javascript"
              src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"
            ></script>
          </form> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Hero() {
  const [modalShow, setModalShow] = React.useState(false);
  let url = `https://overline.network/Overline.dmg`;
  if(platform.os.toString().includes(`Windows`)) url = `https://overline.network/Overline.exe`;
  if(platform.os.toString().includes(`Linux`)) url = `https://overline.network/Overline.AppImage`;

  return (
    <div id="main">
      <section className="hero">
        <div className="hero-wrapper">
          <h2 className="bigtitle main-title">
            Peer to peer digital asset trading.
            <span>
              {" "}
              <br></br>No hidden fees, no middlemen, and no account necessary.
            </span>
          </h2>
          <div className="dropdown">
            <div className="btn btn-beta">
              <svg>
                <rect x="0" y="0" fill="none" width="100%" height="100%" />
              </svg>
              Download
            </div>
            <div class="dropdown-content">
              <a href={url}></a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Hero;
