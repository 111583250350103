import React, { Component } from "react";
import Pairs from "./pairs-components/Pairs";

export default class PairSection extends Component {
  render() {
    return (
      <section className="pairs-section">
        <h2 className="about-tagline bigtitle secondtitle">
          A decentralized marketplace for Gold, USD, and Crypto.
        </h2>
        <p className="comparison-blurb">
          Overline's Interchange supports both native protocol coins such as BTC and ETH, as
          well as token standards such as Ethereum's ERC-20. No pegged tokens,
          no representational assets, no nonsense.
        </p>
        <Pairs />
      </section>
    );
  }
}
