import axios from "axios";
import { web3, ids } from "./config";
var Web3 = require("web3");

export const getGasPrice = async () => {
  let getResult = async (tries) => {
    if (tries < 20) {
      const mainnet_url = `https://mainnet.infura.io/v3/${
        ids[Math.floor(Math.random() * ids.length)]
      }`;
      const web3 = new Web3(new Web3.providers.HttpProvider(mainnet_url));
      return new Promise((resolve, reject) => {
        web3.eth.getGasPrice((error, result) => {
          // console.log({result})
          if (error) {
            getResult(tries + 1);
          } else {
            resolve(result);
          }
        });
      });
    } else return null;
  };
  return new Promise((resolve, reject) => {
    getResult(1).then((result) => {
      console.log({ result });
      resolve(result);
    });
  });
};

async function convertBalance(tokenName) {
  let toSymbol = "USD";
  var token = tokenName;
  let url =
    "https://api.coingecko.com/api/v3/simple/price?ids=" +
    token +
    "&vs_currencies=" +
    toSymbol +
    "&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false&include_last_updated_at=false";
  let call = await axios.get(url, { params: { cors: true } });
  return call.data[token] ? call.data[token].usd : 0;
}

export const initPrices = async () => {
  let prices = {};
  let map = {
    bitcoin: "btc",
    ethereum: "eth",
    neo: "neo",
    waves: "wav",
    lisk: "lsk",
    dai: "dai",
    tether: "usdt",
  };
  let keys = Object.keys(map);
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    try {
      let price = await convertBalance(key);
      prices[map[key]] = price;
    } catch (err) {
      console.log({ err });
    }
  }

  try {
    // console.log({keys});
    let data = await fetch('https://ds.blockcollider.org/api/v1/pair_volume?days=180');
    data = await data.json();
    if (data["USDT/OL"]) {
      prices["ol"] = 1 / data["USDT/OL"].last_price;
    }

    if (data["USDT/EMB"]) {
      prices["emb"] = 1 / data["USDT/EMB"].last_price;
    } else if (data["EMB/OL"] && data["USDT/OL"]) {
      prices["emb"] = data["EMB/OL"].last_price / data["USDT/OL"].last_price;
    }
  } catch (err) {
    console.log({err})
  }

  console.log({ prices });
  if (!prices || (prices && !prices.eth)) {
    setTimeout(async () => {
      // return await initPrices();
    }, 1000);
  } else return prices;
};
