import React from "react";

const sliderItems = [
  {
    webDescription:
      "The Interchange can be downloaded and accessed completely free of charge, and does not collect any personal information whatsoever.",
    featureName: "01 - Getting Started",
    webPageTitle: "Download the Interchange application for free",
    btnTitle: "01",
    imageUrl: "https://i.imgur.com/XJOzij7.gif"
  },
  {
    webDescription:
      "Interchange is a completely client-side application. This means that you maintain complete control over your wallets.",
    featureName: "02 - Getting Started",
    webPageTitle: "Link your wallets to your Interchange Portfolio.",
    btnTitle: "02",
    imageUrl: "https://i.imgur.com/2dNQ5M8.gif"
  },
  {
    webDescription:
      "Once your wallet is linked, you are good to go. Its time to take back your financial freedom.",
    featureName: "03 - Getting Started ",
    webPageTitle: "Make or take an order, and start trading!",
    btnTitle: "03",
    imageUrl: "https://i.imgur.com/RAZguXx.gif"
  }
];

const GettingStarted = () => <Slider items={sliderItems} />;

class Slider extends React.Component {
  constructor() {
    super();
    this.state = {
      currentIndex: 0,
      items: []
    };
  }

  handleSelect = e => {
    this.setState({ currentIndex: Number(e.target.dataset.id) });
  };

  componentDidMount() {
    const { items } = this.props;
    if (items) {
      this.setState({ items });
    }
  }

  render() {
    const { currentIndex, items } = this.state;
    const sliderContent = items.map((item, index) => {
      const left = `${index * 100}%`;
      const backgroundImage = `url(${item.imageUrl})`;
      const sty = { left, backgroundImage };
      const cls = `slide ${index == currentIndex ? "active" : ""}`;
      return <div key={index} className={cls} style={sty}></div>;
    });
    const selectItems = items.map((item, index) => {
      const cls = `select__item ${index == currentIndex ? "active" : ""}`;
      return (
        <span
          key={index}
          data-id={index}
          className={cls}
          onClick={this.handleSelect}
        >
          {item.btnTitle}
        </span>
      );
    });

    const selectedItemObj = items[currentIndex];
    let webPageTitle = "Overline Interchange";
    let webDescription = "";
    let featureName = "";
    if (selectedItemObj) {
      webPageTitle = selectedItemObj.webPageTitle;
      webDescription = selectedItemObj.webDescription;
      featureName = selectedItemObj.featureName;
    }
    return (
      <section id="setup" className="runningBorderless">
        <div className="setupWrapper">
          <h2 className="about-tagline bigtitle secondtitle">
            Simple setup.
            <br />
            <span>Start trading in seconds.</span>
          </h2>
          <div className="slider">
            <div className="slider__panel slider__panel__controls">
              <div className="feature">{featureName}</div>
              <div className="title">{webPageTitle}</div>
              <div className="select">{selectItems}</div>
              <div className="description comparison-blurb"> {webDescription}</div>
            </div>
            <div className="slider__panel slider__panel__frame">
              {sliderContent}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default GettingStarted;
